import { render, staticRenderFns } from "./Navi.vue?vue&type=template&id=535fe4d6&scoped=true&lang=pug&"
import script from "./Navi.vue?vue&type=script&lang=js&"
export * from "./Navi.vue?vue&type=script&lang=js&"
import style0 from "./Navi.vue?vue&type=style&index=0&id=535fe4d6&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "535fe4d6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\git\\front\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('535fe4d6')) {
      api.createRecord('535fe4d6', component.options)
    } else {
      api.reload('535fe4d6', component.options)
    }
    module.hot.accept("./Navi.vue?vue&type=template&id=535fe4d6&scoped=true&lang=pug&", function () {
      api.rerender('535fe4d6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Navi.vue"
export default component.exports