<template lang="pug">
  nav.nav.nav-pills.nav-justified(v-if="isLogin")
    b-nav-item(to="/" exact="" exact-active-class="active") HOME
    b-nav-item(to="/record/top" exact="" ) 記録を見る
    b-nav-item(to="/record/check_in/gps_search" exact="") 記録する
  nav.nav.nav-pills.nav-justified(v-else)
    b-nav-item#create-user(to="/register_user/send_auth" exact="") 
      span 新規ユーザー登録
    b-nav-item#login(to="/login" exact="")
      span ログイン
</template>

<script>
export default {
  data() {
    return {
      isLogin: this.$user.isLogin,
    }  
  }
}
</script>

<style lang="stylus" scoped>
.nav
  background-color #171D32
  a
    color #c3c4c6
    border-bottom none
    &:hover
      background #0664a9
      transition background-color .5s
      color #FFF
      border-bottom 2px solid #9D9A78

.nav-pills .nav-link
  border-radius 0px

.nav-link.active
  background-color #171D32
  color #fff
  border-bottom 2px solid #9D9A78

#create-user
  span
    color #b6c3cc
#login
  span
    color #b6c3cc

// 中デバイス（992px 以上）
@media (min-width 992px)
  .nav
    display none
</style>