var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLogin
    ? _c(
        "nav",
        { staticClass: "nav nav-pills nav-justified" },
        [
          _c(
            "b-nav-item",
            { attrs: { to: "/", exact: "", "exact-active-class": "active" } },
            [_vm._v("HOME")]
          ),
          _c("b-nav-item", { attrs: { to: "/record/top", exact: "" } }, [
            _vm._v("記録を見る")
          ]),
          _c(
            "b-nav-item",
            { attrs: { to: "/record/check_in/gps_search", exact: "" } },
            [_vm._v("記録する")]
          )
        ],
        1
      )
    : _c(
        "nav",
        { staticClass: "nav nav-pills nav-justified" },
        [
          _c(
            "b-nav-item",
            {
              attrs: {
                id: "create-user",
                to: "/register_user/send_auth",
                exact: ""
              }
            },
            [_c("span", [_vm._v("新規ユーザー登録")])]
          ),
          _c(
            "b-nav-item",
            { attrs: { id: "login", to: "/login", exact: "" } },
            [_c("span", [_vm._v("ログイン")])]
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }